import { navigate } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout/Layout'
import  legalImg from '../images/legal.svg'

import * as classes from '../styles/legal.module.scss'

const actions_link_list = [
  {
    label: "Terms of service",
    url: "/terms_of_service"
  },
  {
    label: "Privacy Policy",
    url: "/privacy_policy"
  },
  {
    label: "Cookie Plicy",
    url: "/cookie_policy"
  },
  {
    label: "Disclaimer",
    url: '/disclaimer'
  },
  {
    label: 'Manage cookie preference',
    handler: ()=>{
      if(window?.displayPreferenceModal) {
        window.displayPreferenceModal()
      }
    },
    contentType: "button"
  },
]

export default function legal() {

  const handleClick = (link)=> ()=> {
    if(link.handler) {
      link.handler()
    }else {
      navigate(link.url)
    }
  }
  
  return (
    <Layout>
      <div className={classes.root}>
        <img className={classes.heroImg} src={legalImg} alt="Legal" />
          <h1 className={classes.title}>Terms and Conditions</h1>
          <p className={classes.description}>These terms and conditions outline the rules and regulations for the use of Experts Circle's Website, located at expertscircle.io.</p>
          <div className={classes.actionsList}>
            {actions_link_list.map(link=>{
              return <button key={link.label} onClick={handleClick(link)}>
                {link.label}
              </button>
            })}
          </div>
      </div>
    </Layout>
  )
}
